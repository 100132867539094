import React from 'react'
import { Link } from 'react-router-dom'
import './footer.css'

const Footer = () => {
    return(
        <footer>
            <p>
                <span className="copyright">&copy; 2020 Ciara B Adams</span>
                <span className="privacy"><Link to="/privacy">Privacy</Link></span>
            </p>
        </footer>
    )
}

export default Footer;