import React from 'react'
import './nav.css'
import { Link } from 'react-router-dom'

const Nav = () => {
    return(
        <div>
            <ul className="name">
                <li className="last-link"><h1>Ciara B Adams</h1></li>
            </ul>
            <nav>
                <ul>
                    <li><Link to="/">HOME</Link></li>
                    <li><Link to="/events">EVENTS</Link></li>
                    <li><Link to="/media">MEDIA</Link></li>
                    <li className="coming-soon">Coming Soon!</li>
                    <li className="last-link"><a href="#store">STORE</a></li>
                </ul>
            </nav>
        </div>
    )
}

export default Nav