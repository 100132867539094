import React from 'react'
import './videos.css'

const Videos = () => {
    return(
        <section id="videos">
            <h2>Videos</h2>
            <div className="video-container">
                <div className="embed-container">
                    <iframe 
                        title="Pipe Down" 
                        src='https://www.youtube.com/embed//NQpRzPTm_og' 
                        frameBorder='0'  
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
            <br />
            <div className="video-container">
                <div className="embed-container">
                    <iframe 
                        title="Pipe Down" 
                        src='https://www.youtube.com/embed/lm58jbHshYw' 
                        frameBorder='0'  
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
        </section>
    )
}

export default Videos