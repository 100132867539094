import React, { useState, useEffect } from 'react'
import Social from './social'
import Nav from './nav'
import './header.css'

const Header = () => {
    const [style, setStyle] = useState({
        zIndex: '2'
    })

    const isChrome = !!window.chrome 
        && (!!window.chrome.webstore 
        || !!window.chrome.runtime);

    useEffect(() => {
        if (isChrome) {
            setStyle({
                zIndex: '3'
            })
        }
    }, [isChrome])

    return(
        <header id="top" style={style}>
            <Social />
            <Nav />
        </header>
    )
}

export default Header