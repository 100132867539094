import React from 'react'
import './social.css'

const Social = () => {
    return(
        <div className="social">
            <ul>
                <li><a href="https://music.apple.com/us/artist/ciara-b-adams/1524845540" target="_new">
                    <i className="fab fa-apple"></i></a></li>
                <li><a href="https://open.spotify.com/artist/5CUtjfhuTFlicopAp4mJ94?si=ah4hdjqtQxCHx6NtOhaARQ" target="_new">
                    <i className="fab fa-spotify"></i></a></li>
                <li><a href="https://www.youtube.com/channel/UCQJ3bx889uv8LMXisRZ39xg" target="_new">
                    <i className="fab fa-youtube"></i></a></li>
                <li><a href="https://www.instagram.com/ciara.b.adams/" target="_new">
                    <i className="fab fa-instagram"></i></a></li>
                <li><a href="https://www.facebook.com/CBA-Music-102912701470708/" target="_new">
                    <i className="fab fa-facebook-square"></i></a></li>
            </ul>
        </div>
    )
}

export default Social