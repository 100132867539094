import ciara from '../../images/pictures/ciara.jpg'
import ciara1 from '../../images/pictures/ciara1.jpg'
import ciara2 from '../../images/pictures/ciara2.jpg'
import ciara3 from '../../images/pictures/ciara3.jpg'
import ciara4 from '../../images/pictures/ciara4.jpg'
import ciara5 from '../../images/pictures/ciara5.jpg'
import ciara6 from '../../images/pictures/ciara6.jpg'
import ciara7 from '../../images/pictures/ciara7.jpg'
import ciara8 from '../../images/pictures/ciara8.jpg'

export default [ ciara, ciara1,ciara2, ciara3, ciara4, ciara5, ciara6, ciara7, ciara8 ]