import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Header from './components/header/header'
import { Carousel, Videos, Events, Pictures } from './components/content'
import { Footer, Privacy } from './components/footer'
import './App.css'

function App() {
    return(
        <>
        <div id="background">.</div>
        <div className="container">
            <Header />
            <Switch>
                <Route exact path="/">
                    <Carousel />
                </Route>
                <Route path="/events">
                    <Events />
                </Route>
                <Route path="/media">
                    <Videos />
                    <Pictures />
                </Route>
                <Route path="/store">
                    
                </Route>
                <Route path="/privacy">
                    <Privacy />
                </Route>
            </Switch>
            <Footer />
        </div>
        </>
    )
}

export default App