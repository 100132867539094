import React, {useState, useEffect} from 'react'
import breakingAddiction from '../../images/covers/BA-sm.png'
import pipeDown from '../../images/covers/pipe_down-sm.png'
import gone from '../../images/covers/gone-sm.png'
import './carousel.css'

const Carousel = () => {
    const [index, setIndex] = useState(0)

    const carImgs = [
        {
            src: gone,
            alt: 'Gone',
            url: 'https://music.apple.com/us/album/gone-feat-tilley-alpha-norris-single/1544140665'
        },
        {
            src: breakingAddiction,
            alt: 'Breaking Addiction',
            url: 'https://music.apple.com/us/album/breaking-addiction/1524983359?i=1524983360'
        },
        {
            src: pipeDown,
            alt: 'Pipe Down',
            url: 'https://music.apple.com/us/album/pipe-down/1524980705?i=1524980978'
        }
    ]

    useEffect(() => {
        const carInterval = setInterval(() => {
            navigation(1)
        }, 7000)
        return () => clearInterval(carInterval)
    })

    const navigation = direction => {
        let current = index
        current += direction

        if (direction === -1 && current < 0) {
            current = carImgs.length - 1;
        }

        if (direction === 1 && !carImgs[current]) {
            current = 0;
        }

        setIndex(current)
    }

    return(
        <div className="carousel-wrapper">
            <h3>NEW MUSIC OUT NOW!</h3>
            <div className="carousel">
                <div className="left-button" onClick={() => navigation(-1)}>{'<'}</div>
                    <a href={carImgs[index].url} target="_new"><img src={carImgs[index].src} alt={carImgs[index].alt} /></a>
                <div className="right-button" onClick={() => navigation(1)}>{'>'}</div>
            </div>
        </div>
    )
}

export default Carousel