import React, { useState } from 'react'
import ciaraImgs from '../../images/pictures'
import './pictures.css'

const Pictures = () => {
    const [styles, setStyles] = useState({
        display: 'none'
    })

    const openModal = image => {
        setStyles({
            display: 'block',
            backgroundImage: `url(${image})`
        })
    }

    const closeModal = () => {
        setStyles({
            display: 'none'
        })
    }

    return(
        <section id="pictures">
            <h2>Pictures</h2>
            <div className="gallery">
                {ciaraImgs.map(image => {
                    return <img src={image} alt="Ciara" onClick={() => openModal(image)} />
                })}
            </div>
            <div className="modal" style={styles} onClick={closeModal}></div>
        </section>
    )
}

export default Pictures