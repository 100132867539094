import React from 'react'
import './events.css'
import flyer6 from '../../images/flyers/flyer6.jpg'
import flyer2 from '../../images/flyers/flyer4.png'
import flyer7 from '../../images/flyers/flyer7.jpg'

const Events = () => {
    return(
        <section id="events">
            <div className="overlay">
                <h2>Events</h2>
                <img src={flyer7} alt="Outlaw Nation Welcome to My House Tour, " />
                <br /><br /><br />
                <img src={flyer2} alt="Lyfstyl Underground Unleashed Part 1, Apr 17th, Orlando Fl" />
                <br /><br /><br />
                <img src={flyer6} alt="Outlaw Nation event 3, April 24th, 151 Flattub Rd Broxton GA" />
            </div>
        </section>
    )
}

export default Events